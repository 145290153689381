import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { cloneDeep, mapValues } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';

import { sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { getLangCode } from 'src/utils/string';
import {
    ProductCategory,
    ConfigurationNodeGroup,
    UseProductsFormReturnValues,
    MappedConfigurationNodeGroup,
    UpdateConfigurationNode,
} from './products.types';

import {
    getConfigurationNodeGroups,
    useGetConfigurationNodeGroupsKey,
    usePostConfigurationNodeGroups,
} from './products.api.hooks';

import productMessages from './products.messages';

interface FormValues {
    [key: string]: number;
}

const mapProductGroupToForm = (
    configurationNodeGroups: ConfigurationNodeGroup[],
): FormValues => {
    return configurationNodeGroups.reduce(
        (acc, configurationNodeGroup: ConfigurationNodeGroup) => ({
            ...acc,
            ...configurationNodeGroup.configurationNodes.reduce(
                (accConfigurationNodes, configurationNode) => ({
                    ...accConfigurationNodes,
                    [configurationNode.id.configurationNodeId]:
                        configurationNode.quantity,
                }),
                {} as FormValues,
            ),
        }),
        {} as FormValues,
    );
};

export const useProductsForm = ({
    category,
    successCallback,
}: {
    category: ProductCategory;
    successCallback: () => void;
}): UseProductsFormReturnValues => {
    const intl = useIntl();
    const language = getLangCode(useSelector(sciLanguageSelector));

    const {
        isLoading,
        isFetching,
        isRefetching,
        data: groups,
        refetch,
    } = useQuery({
        queryKey: [useGetConfigurationNodeGroupsKey, category],
        queryFn: async () => await getConfigurationNodeGroups(category, language),
    });

    useEffect(() => {
        refetch();
    }, [language, refetch]);

    const { isLoading: postIsLoading, mutate } =
        usePostConfigurationNodeGroups(category);

    const [mappedGroups, setMappedGroups] = useState<
        MappedConfigurationNodeGroup[]
    >([]);

    const [loadingNodeId, setLoadingNodeId] = useState<string | undefined>();
    const [groupError, setGroupError] = useState<string | undefined>();
    const [filteredGroups, setFilteredGroups] = useState<
        ConfigurationNodeGroup[]
    >([]);
    const [hiddenGroups, setHiddenGroups] = useState<ConfigurationNodeGroup[]>(
        [],
    );

    const schema = yup.lazy((obj) =>
        yup.object(mapValues(obj, () => yup.number())),
    );

    const { handleSubmit, control, watch, reset } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setFilteredGroups((groups ?? []).filter((group) => !group.hidden));
        setHiddenGroups((groups ?? []).filter((group) => group.hidden));
    }, [groups]);

    useEffect(() => {
        if (!filteredGroups) return;
        const newMappedGroups: MappedConfigurationNodeGroup[] = filteredGroups
            .reduce((acc, group, index, initialGroups) => {
                if (group.parent) {
                    const indexParent = initialGroups.findIndex(
                        (g) =>
                            g.id.configurationNodeGroupId ===
                            group.parent?.id.configurationNodeGroupId,
                    );

                    if (indexParent >= 0) {
                        acc[indexParent].children = acc[indexParent].children
                            ? [
                                  ...(acc[indexParent].children ?? []),
                                  ...(group.configurationNodes ?? []),
                              ]
                            : [...(group.configurationNodes ?? [])];
                    }
                    acc[index].ignore = true;
                }
                return acc;
            }, cloneDeep(filteredGroups) as MappedConfigurationNodeGroup[])
            .filter((group) => !group.ignore);

        setMappedGroups(newMappedGroups);

        const newValues = mapProductGroupToForm(filteredGroups);
        reset({ ...newValues });
    }, [filteredGroups, reset]);

    useEffect(() => {
        const subscription = watch((data, info) => {
            const isManualChange = !!info?.name;

            if (!filteredGroups || !isManualChange) return;

            const configurationNodeItems: UpdateConfigurationNode[] = [
                ...filteredGroups.reduce((acc, group) => {
                    const isGroupThatHasChanged =
                        !!group.configurationNodes.find(
                            (node) => node.id.configurationNodeId === info.name,
                        );

                    group.configurationNodes.forEach((configurationNode) => {
                        const isChangedNode =
                            configurationNode.id.configurationNodeId ===
                            info.name;
                        const quantity =
                            data[configurationNode.id.configurationNodeId];

                        if (
                            quantity > 0 &&
                            (group.multiSelect ||
                                isChangedNode ||
                                !isGroupThatHasChanged)
                        ) {
                            acc.push({
                                id: configurationNode.id,
                                quantity,
                                product: {
                                    id: configurationNode.product?.id,
                                    code: configurationNode.product?.code,
                                    name: configurationNode.product?.name,
                                },
                            });
                        }
                    });
                    return acc;
                }, [] as UpdateConfigurationNode[]),
                ...hiddenGroups.reduce((acc, group) => {
                    group.configurationNodes.forEach((configurationNode) => {
                        const quantity = configurationNode.quantity;

                        if (quantity > 0) {
                            acc.push({
                                id: configurationNode.id,
                                quantity,
                                product: {
                                    id: configurationNode.product?.id,
                                    code: configurationNode.product?.code,
                                    name: configurationNode.product?.name,
                                },
                            });
                        }
                    });
                    return acc;
                }, [] as UpdateConfigurationNode[]),
            ];

            setLoadingNodeId(info.name);
            mutate({
                category,
                configurationNodeItems,
            });
            setGroupError(undefined);
        });
        return () => subscription.unsubscribe();
    }, [watch, mutate, filteredGroups, hiddenGroups, category]);

    const onSubmit = () => {
        setGroupError(undefined);

        for (const group of filteredGroups) {
            if (
                group.required &&
                !group.configurationNodes.find((node) => node.quantity > 0)
            ) {
                setGroupError(
                    group.errorMessage ??
                        intl.formatMessage(
                            productMessages.errorAndWarnings.defaultError,
                        ),
                );
                return;
            }
        }

        successCallback();
    };

    return {
        control,
        isLoading: isLoading || isFetching || isRefetching,
        loadingNodeId: postIsLoading ? loadingNodeId : undefined,
        groups: mappedGroups,
        groupError,
        onSubmit: handleSubmit(onSubmit),
    };
};
